import React, { useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import HowItWorks from './components/HowItWorks';
import HowToInstall from './components/HowToInstall';
import BuyNow from './components/BuyNow';
import FAQ from './components/FAQ';
import ContactUs from './components/ContactUs';
import PaymentForm from './components/PaymentForm'; // Import PaymentForm
import './App.css';
import './components/Home.css';
import './components/HowToInstall.css';
import './components/HowItWorks.css';
import './components/BuyNow.css';
import './components/FAQ.css';
import './components/ContactUs.css';
import './components/Header.css'; // Include header CSS

function App() {
  const homeRef = useRef(null);
  const howItWorksRef = useRef(null);
  const howToInstallRef = useRef(null);
  const buyNowRef = useRef(null);
  const faqRef = useRef(null);
  const contactUsRef = useRef(null);
  const paymentRef = useRef(null); // Create ref for PaymentForm

  const location = useLocation();

  useEffect(() => {
    const scrollToSection = () => {
      switch (location.pathname) {
        case '/howitworks':
          howItWorksRef.current.scrollIntoView({ behavior: 'smooth' });
          break;
        case '/howtoinstall':
          howToInstallRef.current.scrollIntoView({ behavior: 'smooth' });
          break;
        case '/buynow':
          buyNowRef.current.scrollIntoView({ behavior: 'smooth' });
          break;
        case '/paynow': // Add case for payment
          paymentRef.current.scrollIntoView({ behavior: 'smooth' });
          break;
        case '/faq':
          faqRef.current.scrollIntoView({ behavior: 'smooth' });
          break;
        case '/contactus':
          contactUsRef.current.scrollIntoView({ behavior: 'smooth' });
          break;
        default:
          homeRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    };
    scrollToSection();
  }, [location]);

  return (
    <div className="App">
      <Header />
      <div ref={homeRef}>
        <Home />
      </div>
      <div ref={howItWorksRef}>
        <HowItWorks />
      </div>
      <div ref={howToInstallRef}>
        <HowToInstall />
      </div>
      <div ref={buyNowRef}>
        <BuyNow />
      </div>
      <div ref={paymentRef}> {/* Add PaymentForm section */}
        <PaymentForm />
      </div>
      <div ref={faqRef}>
        <FAQ />
      </div>
      <div ref={contactUsRef}>
        <ContactUs />
      </div>
      <Footer />
    </div>
  );
}

export default App;
