import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './BuyNow.css'; // Import the custom CSS file
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
function BuyNow() {
  return (
    <section id="buy" className="my-5">
      <Container>
        <Row>
          <Col className="text-center">
            <h2 className="mb-5">List Products</h2>
            <p>We have a variety of products based on multichains.</p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={4}>
            <Card className="product-card">
              <Card.Img variant="top" src="/assets/images/cryptominer.png" />
              <Card.Body>
                <Card.Title>Multi Chain Scanner</Card.Title>
                <Card.Text className="text-left">
                  Random logic: Random 64-character private key & Random 12 seed phrase like Metamask
                  <br />
                  Support chain: ETH, BNB, POLYGON, BASE, ARBITRUM, ETC
                  <br />
                  Support Token: USDT USDC BTC ETH
                  <br />
                  Support Auto Send: Yes
                  <br />
                  Price / Machine / 1 Year: 2000 USDT
                  <br />
                  Price / Machine / 1 Month: 200 USDT
                </Card.Text>
                <div className="button-group">
                  <Button variant="primary" href="https://disk.yandex.com/d/QSDnr8-qX1VL6A" target="_blank">
                    Download
                  </Button>
                  <Link to="/paynow">
                  <Button variant="primary">
                    Buy Now
                  </Button>
                  </Link>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default BuyNow;
