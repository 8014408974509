import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegramPlane } from '@fortawesome/free-brands-svg-icons';
import './ContactUs.css'; // Import the custom CSS file

const teamMembers = [
  {
    name: 'Ace',
    role: 'Developer',
    image: '/assets/images/avatar.png',
    telegram: 'https://t.me/ace_cryptominer'
  },
  // Add more team members here
];

const ContactUs = () => {
  return (
    <section id="contact" className="my-5">
      <Container>
        <Row>
          <Col>
            <h2 className="text-center mb-5">Our Amazing Team Members</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          {teamMembers.map((member, index) => (
            <Col md={4} key={index} className="mb-4">
              <Card className="team-card text-center">
                <Card.Img variant="top" src={member.image} className="team-image" />
                <Card.Body>
                  <Card.Title>{member.name}</Card.Title>
                  <Card.Text>{member.role}</Card.Text>
                  <Button variant="primary" href={member.telegram} target="_blank">
                    <FontAwesomeIcon icon={faTelegramPlane} /> {/* Add FontAwesome icon here */}
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default ContactUs;
