import React from 'react';
import { Container, Row, Col, Button, Collapse } from 'react-bootstrap';
import './FAQ.css'; // Import the custom CSS file

const faqData = [
  { id: 1, question: "What Is A Seed Phrase?", answer: "A seed phrase is a cluster of 12 random words that are generated during the setup for all crypto wallets. It is used as an emergency backup to restore access to the funds." },
  { id: 2, question: "What does the software do exactly?", answer: "Our software utilizes the most cutting-edge algorithms available to the public to rapidly generate and test thousands of potential seed phrase combinations against generated wallets every few seconds so you are able to unlock access to the lost wallet." },
  { id: 3, question: "How much a day will I earn?", answer: "Sometimes a day absolutely nothing would be found, even a few days of silence often occur, sometimes in a day finds 5-10 wallets at once." },
  { id: 4, question: "Is it legal?", answer: "Given that most wallets are old forgotten crypto wallets whose owners have most likely forgotten their accesses to them, I think its legal. I compare it to looking for gold jewelry by the sea-coast, when people with a device walk around looking for lost rings, earrings, etc., no one can say it’s illegal. I think it is the same here! But to avoid any issues, you have to use the software on RDP." },
  { id: 5, question: "How quickly does the software work?", answer: "Speeds can reach up to millions of wallets per minute and billions of wallets per day, depending on the processing power of your device." },
  { id: 6, question: "Why do you sell software?", answer: "We sell software because we want to expand our software and also because we can’t open all crypto wallets ourselves anyway." }
];

class FAQ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: null,
    };
  }

  toggleCollapse = (id) => {
    this.setState({ open: this.state.open === id ? null : id });
  };

  render() {
    return (
      <section id="faq" className="my-5">
        <Container>
          <Row>
            <Col>
              <h2 className="text-center mb-5">Frequently Asked Questions</h2>
              <p className="text-center mb-5">Please check this FAQ first before contacting us.</p>
            </Col>
          </Row>
          <Row>
            {faqData.map((faq) => (
              <Col md={6} key={faq.id} className="mb-4">
                <div className="faq-item">
                  <Button
                    onClick={() => this.toggleCollapse(faq.id)}
                    aria-controls={`collapse-text-${faq.id}`}
                    aria-expanded={this.state.open === faq.id}
                    className="faq-question"
                  >
                    <span className="faq-number">{`0${faq.id}.`}</span> {faq.question}
                  </Button>
                  <Collapse in={this.state.open === faq.id}>
                    <div id={`collapse-text-${faq.id}`} className="faq-answer">
                      {faq.answer}
                    </div>
                  </Collapse>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    );
  }
}

export default FAQ;
