import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import './HowItWorks.css'; // Import the custom CSS file

const HowItWorks = () => {
  return (
    <section id="how-it-works" className="my-5">
      <Container>
        <Row>
          <Col>
            <div className="section-title">
              <h2>How it works</h2>
            </div>
            <div className="content-wrapper">
              <h3>Understanding Seed Phrase and Private Key</h3>
              <p>
                Each cryptocurrency wallet has a private key generated by a 12-24 word mnemonic phrase that serves as a gateway to your wallet through any device or cryptocurrency wallet app, such as Trust Wallet or Metamask.
              </p>
              <p>
                These words or private key are automatically generated by a special algorithm when you create a wallet through any crypto wallet app.
              </p>
              <p>
                Afterward, it assess the balance of the wallets and provide the results: a crypto wallet and key to it, which could potentially contain coins that forgotten on these wallets.
              </p>
              <h4>To earn with our software easy and stable!</h4>
              <ul>
                <li>You don’t need special knowledges or skills</li>
                <li>You don’t need to fix losses like traders</li>
                <li>You don’t need huge capital for making results</li>
                <li>You don’t need to depend on the markets to get profit</li>
              </ul>
              <p>
                The best way to make money with cryptocurrency is to get it from abandoned/forgotten wallets.
              </p>
              <p>
                The software tests thousands of potential seed phrase combinations against generated wallets every few seconds so you are able to unlock access to the lost wallet.
              </p>
              <div className="text-center">
                <Button variant="primary" href="https://t.me/CryptoMiner_Software">View info</Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HowItWorks;
