// PaymentForm.js
import React, { useState } from 'react';
import { Form, Card, Button, Container, Row, Col, Modal } from 'react-bootstrap';
import './PaymentForm.css'; // Import custom CSS for additional styling

const chainData = [
  {
    name: 'Bitcoin',
    address: '1CoKMgizzaARBgD7M8Y9usYR24iCX9TrH3',
    tokens: ['BTC']
  },
  {
    name: 'Ethereum',
    address: '0xf57df5F7DEfd59E35076a38fF32Cb7296E606941',
    tokens: ['ETH', 'USDT', 'USDC']
  },
  {
    name: 'Binance Smart Chain',
    address: '0xf57df5F7DEfd59E35076a38fF32Cb7296E606941',
    tokens: ['BNB', 'USDT', 'USDC']
  },
  {
    name: 'Tron',
    address: 'TWfC9RsGeKeEFnUvWchLaveJ91SQGpCZhi',
    tokens: ['TRX', 'USDT', 'USDC']
  },
  {
    name: 'Solana',
    address: 'C8QDZcHJ9ubQTUfU61E8Ec5PHPxbnmmku7HmLKY5L2JA',
    tokens: ['SOL', 'USDT', 'USDC']
  },
  {
    name: 'Litecoin',
    address: 'LWXXt8anzVYKTzhhmfwwSTQsTNRdRqZY6o',
    tokens: ['LTC']
  }
];

const PaymentForm = () => {
  const [selectedChain, setSelectedChain] = useState(chainData[0].name);
  const [selectedToken, setSelectedToken] = useState(chainData[0].tokens[0]);
  const [qrValue, setQrValue] = useState(chainData[0].address);
  const [copySuccess, setCopySuccess] = useState('');
  const [showModal, setShowModal] = useState(false);

  const handleChainChange = (e) => {
    const chain = chainData.find(chain => chain.name === e.target.value);
    setSelectedChain(chain.name);
    setSelectedToken(chain.tokens[0]);
    setQrValue(chain.address);
    setCopySuccess('');
  };

  const handleTokenChange = (e) => {
    const chain = chainData.find(chain => chain.name === selectedChain);
    setSelectedToken(e.target.value);
    setQrValue(chain.address); // Adjust this if you need to include the token in the QR code value
    setCopySuccess('');
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(qrValue).then(() => {
      setCopySuccess('Address copied successfully!');
    }, (err) => {
      setCopySuccess('Failed to copy the address. Please try again.');
    });
  };

  const handleTransferredClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Container className="payment-form-container">
      <Row className="justify-content-center">
        <Col md={6}>
          <Card className="p-4 shadow payment-card">
            <Card.Body>
              <Card.Title className="text-center">Payment</Card.Title>
              <Form>
                <Form.Group controlId="formChain">
                  <Form.Label>Blockchain</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedChain}
                    onChange={handleChainChange}
                  >
                    {chainData.map((chain) => (
                      <option key={chain.name} value={chain.name}>
                        {chain.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="formToken">
                  <Form.Label>Token</Form.Label>
                  <Form.Control
                    as="select"
                    value={selectedToken}
                    onChange={handleTokenChange}
                  >
                    {chainData
                      .find(chain => chain.name === selectedChain)
                      .tokens.map((token) => (
                        <option key={token} value={token}>
                          {token}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
              </Form>
              <div className="text-center mt-4">
                <p className="mt-3">Wallet Address: {qrValue}</p>
                <Button variant="primary" onClick={copyToClipboard} style={{ backgroundColor: '#8a2be2', borderColor: '#8a2be2' }} className="button-custom mb-2">Copy Address</Button>
                {copySuccess && <p className="mt-2">{copySuccess}</p>}
                <Button variant="success" onClick={handleTransferredClick} style={{ backgroundColor: '#8a2be2', borderColor: '#8a2be2' }} className="button-custom mt-2">Transferred</Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Transfer Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Your transfer has been noted. Please contact the admin via Telegram: <a href="https://t.me/ace_cryptominer" target="_blank" rel="noopener noreferrer">https://t.me/ace_cryptominer</a>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default PaymentForm;
