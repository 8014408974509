import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './Home.css';
import { Link } from 'react-router-dom';

const products = [
  {
    name: 'Multi Chain Scanner',
    description: 'Random logic: Random 64-character private key & Random 12 seed phrase like Metamask',
    priceYear: '2000 USDT',
    priceMonth: '200 USDT',
    image: '/assets/images/cryptominer.png',
    link: 'https://disk.yandex.com/d/QSDnr8-qX1VL6A',
  },
];

const cryptocurrencies = ['ETH', 'BNB', 'BTC', 'USDT'];

const Home = () => {
  const [currentCrypto, setCurrentCrypto] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentCrypto((prevCrypto) => (prevCrypto + 1) % cryptocurrencies.length);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  return (
    <section id="home" className="my-5">
      <Container>
        <Row className="text-center mb-5">
          <Col>
            <h1>SCAN & HACK <span className="highlighted-text">{cryptocurrencies[currentCrypto]}</span> FROM LOST WALLETS</h1>
            <p>The best way to make money with cryptocurrency is to recover it from abandoned or forgotten wallets,<br />as it often holds significant value that people have overlooked.</p>
            <Button variant="primary" href="https://t.me/CryptoMiner_Software" target="_blank">Join Our Telegram Channel</Button>
          </Col>
        </Row>
        <Row className="justify-content-center">
          {products.map((product, index) => (
            <Col md={4} key={index} className="mb-4">
              <Card className="product-card text-center">
                <Card.Img variant="top" src={product.image} className="product-image" />
                <Card.Body>
                  <Card.Title>{product.name}</Card.Title>
                  <Card.Text>{product.description}</Card.Text>
                  <p className="price-text">Price / Machine / 1 Year: {product.priceYear}</p>
                  <p className="price-text">Price / Machine / 1 Month: {product.priceMonth}</p>
                  <div className="button-container">
                    <a href={product.link} target="_blank" rel="noopener noreferrer">
                      <Button variant="primary" className="custom-button">Download</Button>
                    </a>
                    <Link to="/paynow">
                      <Button variant="success" className="custom-button">Buy Now</Button>
                    </Link>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Home;